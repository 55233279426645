@font-face {
  font-family: "CaviarDreams";
  src: url("./assets/Fonts/proximanova-regular-webfont.woff") format("woff");
}
@font-face {
  font-family: "CaviarDreamsBold";
  src: url("./assets/Fonts/proxima_nova_bold-webfont.woff") format("woff");
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: CaviarDreams !important;
}
